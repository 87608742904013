<template>
	<div id="app" style="background-color: #fff;">
		<router-view></router-view>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	// import Axios from 'axios';

	export default {
		name: 'App',
		computed: {
			...mapState(['theme', 'tab_routes', 'history_routes'])
		},
		methods: {
			handleBeforeUnload () {
				// e.preventDefault();
				this.$http.post(this.$api.URI_CONFIG, {action: 'setRoutes', tabs: this.tab_routes, histories: this.history_routes});
			}
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					var e = document.documentElement;
					// var s = 'mini';
					// if (e.clientWidth > 1080) {
					// 	s = 'small';
					// }
					// if (e.clientWidth > 1400) {
					// 	s = 'medium';
					// }
					this.$store.commit('setTheme', {
						...this.theme,
						width: e.clientWidth,
						height: e.clientHeight,
						is_mobile: this.$utils.isMobile()
					});
				})()
			};
			// document.addEventListener('visibilitychange', () => {
			// 	if (document.hidden) {
			// 		this.$http.post(this.$api.URI_CONFIG, {action: 'setRoutes', tabs: this.tab_routes, histories: this.history_routes});
			// 	}
			// });  sdfds
			window.addEventListener('beforeunload', (e) => {this.handleBeforeUnload(e)});
			// window.addEventListener('unload', (e) => this.handleBeforeUnload(e));
			// Axios({
			// 	method:'post',
			// 	url:'http://www.sz-wanqu.com/apia/auth/login',
			// 	data: {
			// 		firstName: 'Fred',
			// 		lastName: 'Flintstone'
			// 	}
			// }).then(function(response) {
			// 	console.log(response)
			// 	// response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))
			// });
		},
		destroyed() {
			window.removeEventListener('beforeunload', (e) => {this.handleBeforeUnload(e)});
			// window.removeEventListener('unload', (e) => this.handleBeforeUnload(e));
		}
		// https://scrm.ycfawu.com?code=DDogDMDNnyuE6wIabk3Xc_M71YbceoQA_OZ5FXft_tyfs&state=loginState
	}
</script>

