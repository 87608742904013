/**
 * 缓存数据优化
 * var cache = require('utils/cache.js')
 * import cache from '../cache'
 * 使用方法
 * 一、设置缓存
 *     string    cache.put('k', 'string你好啊')
 *     json      cache.put('k', { "b": "3" }, 2)
 *     array     cache.put('k', [1, 2, 3])
 *     boolean   cache.put('k', true)
 * 二、读取缓存
 *     默认值    cache.get('k')
 *     string    cache.get('k', '你好')
 *     json      cache.get('k', { "a": "1" })
 * 三、移除/清理  
 *     移除: cache.remove('k')
 *     清理：cache.clear()
 * @type {String}
 */

// 缓存过期时间后缀
const postfix = '_expired_at'

module.exports = {
	put(k, v, s) {
		localStorage.setItem(k, v)
		var t = parseInt(s)
		if (t > 0) {
			localStorage.setItem(k + postfix, Date.parse(new Date()) / 1000 + t + "")
		} else {
			localStorage.removeItem(k + postfix)
		}
	},
	get(k, c = true, d) {
		var t = parseInt(localStorage.getItem(k + postfix))
		if (c && t && t < Date.parse(new Date()) / 1000) {
			if (d) {
				return d
			}
			return false
		}
		var res = localStorage.getItem(k)
		if (res) {
			return res
		}
		if (d == undefined || d == "") {
			d = false
		}
		return d
	},
	remove(k) {
		localStorage.removeItem(k)
		localStorage.removeItem(k + postfix)
	},
	clear() {
		localStorage.clear()
	}
}