import Vue from 'vue'
import Vuex from 'vuex'
import Utils from '../utils'
import Api from '../utils/api'
import Http from '../utils/http'
import Cache from '../utils/cache'
// 各个模块
import editor from './modules/editor'
import user from './modules/user'

Vue.use(Vuex)
// 获取登录信息
const user_token = Cache.get("access_token", false);

export default new Vuex.Store({
    state: {
        hasLogin: user_token ? true : false,
        admin_user: null,
        current_shop: null,
        users: null,
        clues: null,
        goods: null,
        orders: null,
        clients: null,
        employee: null,
        employees: null,
        enrollment: null,
        contracts: null,
        client_fees: null,
        client_follows: null,
        social_accounts: null,
        manage_shops: [],
        models: [],
        oa_users: [],
        oa_departments: [],
        regions: [],
        top_routes: [],
        tab_routes: JSON.parse(Cache.get("tab_routes")) || [{name: 'Home', path: '/', fullPath: '/', meta: {title: '工作台'}, close: false}],
        api_methods: new Map(),
        api_actions: new Map(),
        admin_routes: new Set(),
        history_routes: JSON.parse(Cache.get("route_histories")) || [],
        config_uploads: [],
        admin_permissions: [],
        theme: {
            size: 'mini',
            group: '集团总部',
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            is_mobile: Utils.isMobile()
        }
    },
    modules: {
        editor,
        user
    },
    mutations: {
        login (state, data) {
            state.hasLogin = true;
            Cache.put("access_token", data.access_token, data.expires_in);
        },
        setTheme (state, data) {
            state.theme = data
        },
        setUsers (state, data) {
            state.users = data
        },
        setOaUsers (state, data) {
            state.oa_users = data
        },
        setEmployee (state, data) {
            state.employee = data
        },
        setModels (state, data) {
            state.models = data
        },
        setEnrollment (state, data) {
            state.enrollment = data
        },
        setDepartments (state, data) {
            state.oa_departments = data
        },
        setClues (state, data) {
            state.clues = data
        },
        setGoods (state, data) {
            state.goods = data
        },
        setOrders (state, data) {
            state.orders = data
        },
        setClients (state, data) {
            state.clients = data
        },
        setContracts (state, data) {
            state.contracts = data
        },
        setEmployees (state, data) {
            state.employees = data
        },
        setClientFees (state, data) {
            state.client_fees = data
        },
        setClientFollows (state, data) {
            state.client_follows = data
        },
        setRegions (state, data) {
            state.regions = data
        },
        setAdminUser (state, data) {
            state.admin_user = data;
        },
        setSocialAccounts (state, data) {
            state.social_accounts = data;
        },
        setCurrentShop (state, data) {
            state.current_shop = data;
        },
        setManageShops (state, data) {
            state.manage_shops = data;
        },
        setApiPermissions (state, data) {
            state.api_actions = data.actions;
        },
        setAdminPermissions (state, data) {
            state.admin_permissions = data;
        },
        setTopRoutes (state, data) {
            state.top_routes = data;
        },
        setConfigUploads (state, data) {
            state.config_uploads = data;
        },
        setTabRoutes (state, data) {
            state.tab_routes = data;
            Cache.put('tab_routes', JSON.stringify(data))
        },
        setAdminRoutes (state, data) {
            state.admin_routes = data;
        },
        setHistoryRoutes (state, data) {
            state.history_routes = data;
            Cache.put("route_histories", JSON.stringify(data))
        },
        logout (state) {
            state.hasLogin = false;
            state.admin_user = null;
            state.api_actions = new Map();
            state.admin_routes = new Set();
            state.admin_permissions = [];
            Cache.remove("tab_routes");
            Cache.remove("access_token");
        }
        // 【刷 iPhone 乘公交】http://adshare.toutiao.com/group/6833588531479183886/?app=news_article_lite&timestamp=1592924489&req_id=202006232301290100140481371F3A3177&group_id=6833588531479183886&tt_from=copy_link&utm_source=copy_link&utm_medium=toutiao_ios&utm_campaign=client_share
    },
    actions: {
        async logout ({commit}) {
            // uni.showLoading({title: '正在注销退出...'})
            // let res = await Http.delete('auth/current')
            commit('logout')
            // uni.hideLoading()
        },
        async getConfig ({commit}) {
            const res = await Http.get(Api.URI_CONFIG);
            const { result } = res.data;
            const routes = new Set();
            const actions = new Map();
            // 遍历白名单权限[路由：动作]
            for (let p in result.permissions) {
                const action = result.permissions[p];
                actions.has(p) ? actions.get(p).add(...action) : actions.set(p, new Set([...action]));
            }
            // 遍历当前用户权限
            result.admin_permissions.map((r) => {
                routes.add(r.path);
                if (r.api) {
                    actions.has(r.api) ? actions.get(r.api).add(r.action) : actions.set(r.api, new Set([r.action]));
                }
            });
            commit('setUsers', result.users);
            commit('setModels', result.models);
            commit('setOaUsers', result.oa_users);
            commit('setEmployee', result.employee);
            commit('setEnrollment', result.enrollment);
            commit('setDepartments', result.oa_departments);
            commit('setClues', result.clues);
            commit('setGoods', result.goods);
            commit('setClientFees', result.client_fees);
            commit('setClientFollows', result.client_follows);
            commit('setOrders', result.orders);
            commit('setRegions', result.regions);
            commit('setClients', result.clients);
            commit('setContracts', result.contracts);
            commit('setEmployees', result.employees);
            commit('setAdminUser', result.admin_user);
            commit('setSocialAccounts', result.social_accounts);
            commit('setCurrentShop', result.current_shop);
            commit('setManageShops', result.manage_shops);
            commit('setConfigUploads', result.config_uploads);
            commit('setTopRoutes', Utils.topRoute(result.top_routes, routes));
            commit('setApiPermissions', { actions });
            commit('setAdminPermissions', result.admin_permissions);
            commit('setAdminRoutes', routes);
        }
    }
})

