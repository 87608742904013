const PROTOCOL = "https:"
const HOST = "api.ycfawu.com"
const ROOTPATH = `${PROTOCOL}//${HOST}/`

const configDict = {
	development: {
		baseURL: 'http://localhost:4000'
	},
	production: {
		baseURL: ""
	}
}

const currentConfigKey = process.env.NODE_ENV
const isDev = process.env.NODE_ENV === 'development'
const isTest = process.env.NODE_ENV === 'development'

module.exports = {
	PROTOCOL: PROTOCOL,
	HOST: HOST,
	APIHOST: ROOTPATH + "adminv1/",
	ROOTPATH: ROOTPATH,
	WORK_WECHAT_APPID: "ww91186911e063b227",
	WORK_WECHAT_AGENTID: "1000002",
	WORK_WECHAT_REDIRECT_URI: "http://sa.dajing.ren/login",
	APP_NAME: "管理系统",
	COMPANY: "企业管理",
	SLOGAN: "做爱做的事，想要的都在这里",
	SHARE_DESC: "官方商城|正品保障\n保密配送|货到付款",
	SHARE_IMG: ROOTPATH + "getheadimg.png",
	isDevelop: (isDev || isTest),
	...configDict[currentConfigKey],
	// h5模式宽高
	canvasH5Width: 375,
	canvasH5Height: 644,
	PAGE_LAYOUT: "total, sizes, prev, pager, next, jumper",
	ROUTE_WHITELIST: ['config', 'login', 'pinyin', 'passwd', 'uploads', 'ueditor'],
	PAHE_SIZES: [10, 20, 30, 40, 50, 100, 200, 300],
	DATE_PICKER: {
		shortcuts: [{
			text: '今天',
			onClick (p) {
				const t = new Date();
				const y = t.getFullYear();
				const m = t.getMonth();
				const d = t.getDate();
				p.$emit('pick', [new Date(y, m, d, 0, 0, 0), new Date(y, m, d, 23, 59, 59)]);
			}
			}, {
			text: '昨天',
			onClick (p) {
				const t = new Date();
				const y = t.getFullYear();
				const m = t.getMonth();
				const d = t.getDate();
				p.$emit('pick', [new Date(y, m, d-1, 0, 0, 0), new Date(y, m, d-1, 23, 59, 59)]);
			}
			}, {
			text: '最近一周',
			onClick (p) {
				const t = new Date();
				t.setTime(t.getTime() - 3600 * 1000 * 24 * 7);
				p.$emit('pick', [t, new Date()]);
			}
			}, {
			text: '最近一个月',
			onClick (p) {
				const t = new Date();
				t.setTime(t.getTime() - 3600 * 1000 * 24 * 30);
				p.$emit('pick', [t, new Date()]);
			}
			}, {
			text: '最近三个月',
			onClick (p) {
				const t = new Date();
				t.setTime(t.getTime() - 3600 * 1000 * 24 * 90);
				p.$emit('pick', [t, new Date()]);
			}
			}, {
			text: '最近半年',
			onClick (p) {
				const t = new Date();
				t.setTime(t.getTime() - 3600 * 1000 * 24 * 180);
				p.$emit('pick', [t, new Date()]);
			}
			}, {
			text: '最近一年',
			onClick (p) {
				const t = new Date();
				t.setTime(t.getTime() - 3600 * 1000 * 24 * 365);
				p.$emit('pick', [t, new Date()]);
			}
		}]
	}
}
