import Axios from 'axios';
import Utils from './index';
import Cache from './cache';
import Config from './config';
import Store from '@/store';
import Route from '@/routes';
import { Message } from 'element-ui';

// 设置全局配置
Axios.defaults.baseURL = Config.APIHOST;
// 添加请求拦截器
Axios.interceptors.request.use((cfg) => {
	// 在发送请求之前检查后端请求数据权限
	const filters = new Set(Config.ROUTE_WHITELIST);
	const { url, method, params, data } = cfg;
	if (!filters.has(url)) {
		let action = params ? (params.action ? params.action : 'default') : 'default';
		if (method === 'post') {
			action = data.action ? data.action : (data.id ? 'update' : 'create');
		}
		if (!Utils.checkAuth(url, action)) return Promise.reject(url+'@'+action+'后端请求数据权限不足！请联系技术人员。');
	}
	// 需要权限认证的路由
	cfg.headers.Authorization = 'Bearer ' + Cache.get("access_token", false);
	return cfg;
}, function (error) {
	// 对请求错误做些什么
	Message.error(error);
	return Promise.reject(error);
});

// 添加响应拦截器
Axios.interceptors.response.use(async (res) => {
	const { code, result } = res.data;
	// 对响应数据做点什么
	if (code == 4008) {
		// 存储新 token
		Cache.put("access_token", result.access_token, result.expires_in);
		// 携带新 token 重新请求
		let repeat = await Axios.request(res.config);
		if ( repeat ) {
			res = repeat;
		}
	}
	if (code == 4001) {
		// 登陆态失效则清除token：没有携带token、token无法再刷新
		Store.commit('logout');
		Route.push({path: '/login', query: { redirect_uri: window.location.href }});
	}
	return res;
}, function (error) {
	// 对响应错误做点什么
	Message.error(error);
	return Promise.reject(error);
});

export default {
	get (url, cfg = null) {
		return new Promise((resolve, reject) => {
			Axios.get(url, cfg).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		});
	},
	put (url, data, cfg = null) {
		return new Promise((resolve, reject) => {
			Axios.put(url, data, cfg).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		})
	},
	post (url, data, cfg = null) {
		return new Promise((resolve, reject) => {
			Axios.post(url, data, cfg).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		})
	},
	delete (url, cfg = null) {
		return new Promise((resolve, reject) => {
			Axios.delete(url, cfg).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			})
		})
	},
	async login (params) {
		return await Axios.request({
			url: 'auth/login',
			data: params,
			method: 'POST'
		}, false)
	}
}
