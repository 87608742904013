import { createUUID, deepClone } from '@/utils/uitls'
import { cloneDeep, merge } from "lodash";
import $config from '@/utils/config'
// 元素配置信息字段
let elementConfig = {
	name: '未命名',
	elName: '',
	animations: [],
	commonStyle: {
		position: 'absolute',
		width: $config.canvasH5Width,
		height: 30,
		top: 200,
		left: 0,
		rotate: 0,
		paddingTop: 0,
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 0,
		marginTop: 0,
		marginLeft: 0,
		marginRight: 0,
		marginBottom: 0,
		borderWidth: 0,
		borderColor: '',
		borderStyle: 'solid',
		borderRadius: 0,
		boxShadow: '',
		fontSize: 16,
		fontWeight: 500,
		lineHeight: 1.4,
		letterSpacing: 0,
		textAlign: 'center',
		color: '#000000',
		backgroundColor: '',
		backgroundImage: '',
		backgroundSize: 'cover',
		opacity: 1,
		zIndex: 1
	},
	config: {
		hide: 0,
		lock: 0,
		edit: 0,
	},
	events: [],
	propsValue: {},
	value: '',
	valueType: 'String'
}
// 背景音乐配置信息字段
let bgmConfig = {
	first: 0,
	right: 20,
	top: 20,
	size: 40,
	icon: 'http://imgv1.citys114.com/article/20230106/m2zzv11ykrymvXlUqTQaQH06migwaT9FRWX5vn4z.png',
	resource: 'https://apiv1.citys114.com/song.mp3',
}
// 翻页配置信息字段
let flipConfig = {
	loop: false,
	autoplay: false,
	effect: 'slide',
	direction: 'horizontal',
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 300,
	delay: 3000,
	fadeEffect: {
		crossFade: false,
	},
	cardsEffect: {
		slideShadows: true,
	},
	flipEffect: {
		slideShadows : true,
		limitRotation : true,
	},
	cubeEffect: {
		slideShadows: true,
		shadow: true,
		shadowOffset: 20,
		shadowScale: 0.94
	},
	coverflowEffect: {
		rotate: 50,
		stretch: 0,
		depth: 100,
		modifier: 1,
		slideShadows : true
	},
	creativeEffect: {
		perspective: true,
		shadowPerProgress: false,
		limitProgress: 1,
		progressMultiplier: 1,
		prev: {
			shadow: true,
			opacity: 0,
			scale: 0,
			origin: 'left bottom',
			translate: [0, 0, -400],
			rotate: [0, 0, 0],
		},
		next: {
			shadow: true,
			opacity: 0,
			scale: 0,
			origin: 'left bottom',
			translate: [0, '100%', 0],
			rotate: [0, 0, 0]
		}
	}
}
// 指示器配置信息字段
let indicatorConfig = {
	size: 5,
	activeColor: 'rgba(255,255,255,0.3)',
	inactiveColor: 'rgba(0,0,0,0.9)',
	mode: 'none',
}
// 页面配置信息字段
let pageConfig = {
	name: '',
	elements: [],
	commonStyle: {
		backgroundColor: '',
		backgroundImage: '',
		backgroundSize: 'cover'
	},
	config: {
		hide: 0,
		long: 0,
		popup: 'center',
		direction: 0
	}
}
// 项目配置信息字段
let projectConfig = {
	name: '未命名名称',
	title: '未命名标题',
	cover: 'https://imgv1.citys114.com/szcitys114/20220301/20220301191310_44888.png',
	width: $config.canvasH5Width,
	height: $config.canvasH5Height,
	description: '我用可视化编辑器做了一个超酷炫的H5，快来看看吧。',
	script: '',
	flip: flipConfig,
	pages: [],
	dialogs: []
}


let getElementConfig = function (element, extendStyle = {}) {
	let elementData = cloneDeep(element)
	let type = elementData.valueType || 'String' // 默认string类型
	let dict = {
		'String': '',
		'Array': [],
		'Object': {},
		'Boolean': false,
		'Number': 0
	}
	let elementConfigData = cloneDeep(elementConfig)
	let config = {
		uuid: createUUID(),
		...elementConfigData,
		elName: elementData.elName,
		propsValue: deepClone(elementData.needProps || {})
	}
	config.commonStyle = merge(config.commonStyle, elementData.defaultStyle)
	config.commonStyle = merge(config.commonStyle, extendStyle)

	config.value = element.defaultValue || dict[type];
	config.valueType = type;
	return config;
}
let copyElement = function(element, extendStyle = {}, offset = 10){
	element = cloneDeep(element);
	element.uuid = createUUID();
	element.commonStyle = merge(element.commonStyle, extendStyle);
	// 加上一点偏移量，以作区分
	element.commonStyle.top = element.commonStyle.top + offset;
	element.commonStyle.left = element.commonStyle.left + offset;
	return element;
}

let getPageConfig = function () {
	return {
		uuid: createUUID(),
		...cloneDeep(pageConfig)
	}
}
let copyPage = function(data){
	let pageData = cloneDeep(data);
	pageData.uuid = createUUID();
	pageData.elements = pageData.elements.map(element => {
		return copyElement(element, {}, 0)
	})
	return pageData;
}

let getProjectConfig = function () {
	let project = cloneDeep(projectConfig)
	project.pages.push({...getPageConfig()})
	return { ...project }
}

/**
 * 获取元素样式
 * @param styleObj
 * @param scalingRatio 缩放比例
 */
let getCommonStyle = function (styleObj, scalingRatio = 1) {
	let needUnitStr = ['width', 'height','top', 'left', 'paddingTop', 'paddingLeft', 'paddingRight', 'paddingBottom', 'marginTop', 'marginLeft', 'marginRight', 'marginBottom', 'borderWidth','fontSize', 'borderRadius', 'letterSpacing']
	let style ={}

	for (let key in styleObj){
		if(needUnitStr.includes(key)){
			style[key] = (styleObj[key] * scalingRatio) + 'px'
		}else{
			style[key] = styleObj[key]
		}
	}
	style.transform = `rotate(${style.rotate}deg)`
	style.backgroundImage = style.backgroundImage ? `url(${style.backgroundImage})` : '';
	return style;
}


// function getEleByElName(elName) {
// 	let list = [];
// 	eleConfig.forEach(item => {
// 		list = [...list, ...item.components]
// 	})
// 	return list.find(v => {return v.elName === elName})
// }

export default {
	bgmConfig,
	pageConfig,
	projectConfig,
	elementConfig,
	flipConfig,
	indicatorConfig,
	getElementConfig,
	getPageConfig,
	getProjectConfig,
	copyPage,
	copyElement,
	getCommonStyle
}
